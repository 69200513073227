<template>
  <v-dialog v-model="dialog" max-width="600" persistent transition="dialog-bottom-transition" @click:outside="$emit('close')" @keydown.esc="$emit('close')">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Detalhes</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-container class="pa-4">
        <vue-json-pretty :deep="5" :data.sync="coupon" highlight-mouseover-node show-line />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty: () => import('vue-json-pretty')
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    coupon: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
